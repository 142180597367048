<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link class="crumbs_item" tag="a" to="/admin/UserManage/index">用户管理 / </router-link>
          <span class="crumbs_item crumbs_last">{{userType != 3?"用户":"机构"}}详情</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="bgDiv" v-if="userType != 3">
          <div class="line_item">
            <p>姓名：<span>{{form.name ? form.name : '-'}}</span></p>
            <p>身份证号：<span>{{form.idNo ? form.idNo : '-'}}</span></p>
            <p>性别：<span>{{form.sex==1 ? '男' : form.sex==2 ? '女' : '未知'}}</span></p>
          </div>
          <div class="line_item">
            <p>用户名：<span>{{form.userName ? form.userName : '-'}}</span></p>
            <p>学历：<span>{{form.degree ? form.degree : '-'}}</span></p>
            <p >身份：<span>{{form.userType ? FunUserType() : '-'}}</span></p>
          </div>
          <div class="line_item">
            <p>籍贯：<span>{{form.pgAddressName ? form.pgAddressName : '-'}}</span></p>
            <p>所在地：<span>{{form.locationName ? form.locationName : '-'}}</span></p>
            <p>民族：<span>{{form.nation ? form.nation : '-'}}</span></p>
          </div>
          <div class="line_item">
            <p>工作年限：<span>{{form.workYears ? form.workYears : '-'}}</span></p>
            <p>所在行业：<span>{{form.industry ? form.industry + ' - ' + form.industrySub : '-'}}</span></p>
            <p>职业：<span>{{form.occupationName ? form.occupationName : '-'}}</span></p>
          </div>
          <div class="line_item">
            <p>工作单位：<span>{{form.workName ? form.workName : '-'}}</span></p>
            <p>手机号：<span>{{form.mobile ? form.mobile : '-'}}</span></p>
            <p v-show="this.$route.query.userType == 1">孩子生日：<span>{{form.childBirthday ? form.childBirthday : '-'}}</span></p>
          </div>
        </div>
        <div class="bgDiv" v-else>
          <p class="title">机构信息</p>
          <div class="line_item">
            <p>机构邮箱：<span>{{form.email ? form.email : '-'}}</span></p>
            <p>机构码：<span>{{form.orgCode ? form.orgCode : '-'}}</span></p>
            <p>机构名称：<span>{{form.orgName ? form.orgName : '-'}}</span></p>
          </div>
          <div class="line_item">
            <p>企业类型：<span>{{form.orgType == 1?'企业':'非企业'}}</span></p>
            <p>地区：<span>{{form.orgProvice ? ((form.orgProvice? form.orgProvice : "") + (form.orgCity? form.orgCity : "") + (form.orgArea? form.orgArea : "") + (form.orgAddress? form.orgAddress : "")) : '-'}}</span></p>
            <p>是否为定点机构：<span>{{form.appointedFlag?'是':'否'}}</span></p>
          </div>
          <div class="line_item">
            <p>企业规模：<span>{{form.dictionaryValue}}</span></p>
            <p>所在行业：<span>{{form.industry}} - {{form.industrySub}}</span></p>
            <p>统一社会信用代码：<span>{{form.institutionCode ? form.institutionCode : '-'}}</span></p>
            <!-- <div>营业执照/事业单位法人证书：<div><img :src="form.businessPhoto" alt=""></div></div> -->
          </div>

          <div class="line_item">
            <p>{{form.orgType == 1?'营业执照/':''}}事业单位法人证书：
              <viewer v-if="form.businessPhoto">
                <img :src="form.businessPhoto" alt="">
              </viewer>
            </p>
            <p>一般纳税人资格证明：
              <viewer v-if="form.taxpayerPhoto">
                <img :src="form.taxpayerPhoto" alt="">
              </viewer>
            </p>
          </div>

          <p class="title">机构联系人信息</p>
          <div class="line_item">
            <p>姓名：<span>{{form.contactName ? form.contactName : '-'}}</span></p>
            <p>证件信息：<span>{{form.contactIdcard ? form.contactIdcard : '-'}}</span></p>
          </div>
          <div class="line_item">
            <p>手机号：<span>{{ form.contactTell ? $encryption('user:infomation:sensitivequery','phone',form.contactTell) : '-' }}</span></p>
            <p>机构职位：<span>{{form.contactPost ? form.contactPost : '-'}}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
      return {
        userType: "",
        form:{},
      }
    },
    // 事件处理器
    methods: {

      getFormDetai(){
        this.loading = true
        this.$ajax({
          method: 'get',
          url: this.userType != 3?"/hxclass-management/user/info/details":"/hxclass-management/organization/info/certification/details",
          params: {
            userId: this.$route.query.userId
          }
        }).then(res => {
          this.loading = false
          if (res.code == 200 && res.success) {
            this.form = res.data
          }
        }).catch(error => {
          this.loading = false
          if (error) {
            this.$message.warning('系统错误');
          }
        })
      },
      FunUserType(){
        // 01.家长，02从业者，03.机构，04.家长+从业者，05.家长+机构，06.从业者+机构，07 家长+从业者+机构
        if(this.form.userType == '1'){
          return '家长'
        } else if (this.form.userType == '2') {
          return '从业者'
        } else if (this.form.userType == '3') {
          return '机构'
        } else if (this.form.userType == '4') {
          return '家长+从业者'
        } else if (this.form.userType == '5') {
          return '家长+机构'
        } else if (this.form.userType == '6') {
          return '从业者+机构'
        } else if (this.form.userType == '7') {
          return '家长+从业者+机构'
        }
      }
    },
    // 生命周期-实例创建完成后调用
    created() {

    },
    // 生命周期-实例挂载后调用
    mounted() {
      this.userType = this.$route.query.userType;
      this.getFormDetai()
    },
    // 生命周期-实例销毁离开后调用
    destroyed() {},
    // 计算属性监听
    computed: {
      
    },
    // 自定义的侦听器
    watch: {}
  }

</script>

<style lang="less" scoped>
  a {
    color: #333333;
  }

  .Up_Down_inner {
    margin: 24px 0;
  }

  .bgDiv {
    .title{
      font-weight: bold;
      margin-bottom: 24px;
    }
    .line_item {
      display: flex;
      margin-bottom: 24px;
      div{
        width: calc(100% / 3);
        div{
          width:400px;
          height: 200px;
          margin-top: 24px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        
      }
      img{
        margin-top: 10px;
        display: block;
        width: 230px;
        cursor: pointer;
      }
      p {
        width: calc(100% / 3);
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        span {
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .line_item:last-child {
      margin-bottom: 0;
    }
  }

</style>
